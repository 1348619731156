import { FunctionComponent, PropsWithChildren } from "react";
import Title from "./Title";

import styles from "./LanguageSelector.module.css";
import { FIDDLE_LANGUAGES, FRIENDLY_LANGUAGE_METADATA } from "@fastly-fiddle/common";
import { Link } from "raviger";
import Icon from "./Icon";

type Props = {
  computeOnly: boolean
};

const LanguageSelector: FunctionComponent<Props> = (props: PropsWithChildren<Props>) => {
  return (
    <>
      <Title />

      <div className="container page">
        <h1>Select a language for your new Fiddle</h1>
        <div className={styles.languages}>
          {FIDDLE_LANGUAGES.filter((lang) => lang !== 'vcl' || !props?.computeOnly).map((lang) => (
            <div key={lang} className={styles.languageCard} data-language={lang}>
              <Link href={`/new/${lang}`}>
                <Icon type={`language-${lang}`} />
                <div className={styles.info}>
                  <h1>{FRIENDLY_LANGUAGE_METADATA[lang].name}</h1>
                  <p>{FRIENDLY_LANGUAGE_METADATA[lang].description}</p>
                </div>
              </Link>
            </div>
          ))}
        </div>
        <br/>
        <Link href="https://www.fastly.com/documentation/reference/compute/sdks/">Learn more about available Fastly Compute SDKs</Link>
      </div>
    </>
  );
};

export default LanguageSelector;
