import { FunctionComponent, useState } from "react";

import styles from "./MaintenanceBanner.module.css";
import Icon from "./Icon";

type Props = Record<string, never>;

const MaintenanceBanner: FunctionComponent<Props> = () => {
  const [isHidden, setInternalHiddenState] = useState(localStorage && Boolean(localStorage.getItem("maintenance-warning-dismissed")));

  if (isHidden) return <></>;

  const dismiss = () => {
    localStorage && localStorage.setItem("maintenance-warning-dismissed", "true");
    setInternalHiddenState(true);
  };

  const maintenanceWindowStart = new Date('August 31, 2024 12:00:00 GMT+01:00');
  const maintenanceWindowEnd = new Date('August 31, 2024 14:00:00 GMT+01:00');

  if (maintenanceWindowEnd < new Date()) {
    return <></>;
  }

  const dayFormatOptions: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
  };
  const timeFormatOptions: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric'
  };

  return (
    <div className={styles.banner}>
      <div className="container">
        <span className={styles.controls}>
          <h1>Scheduled maintenance happening this weekend</h1>
          <a href="#" onClick={() => dismiss()}>
            <Icon type="cross"></Icon>
          </a>
        </span>
        <p>
          Fastly Fiddle will be unavailable between the hours of {maintenanceWindowStart.toLocaleTimeString('en-us', timeFormatOptions)} and {maintenanceWindowEnd.toLocaleTimeString('en-us', timeFormatOptions)} this {maintenanceWindowStart.toLocaleString('en-us', dayFormatOptions)}, as we migrate the application to new infrastructure.
        </p>
      </div>
    </div>
  );
};

export default MaintenanceBanner;
