import { FunctionComponent, Suspense, useState } from "react";

import Columns from "./Columns";
import ToolTip from "./Tooltip";
import Origins from "./Origins";
import RequestEditor from "./RequestEditor";
import RequestSummary from "./RequestSummary";
import Title from "./Title";
import Instructions from "./Instructions";
import TabbedContainer, { TabType } from "./TabbedContainer";
import CodeBlock from "./CodeBlock/CodeBlock";
import Install from "./Install";
import Icon from "./Icon";

import {
  Fiddle,
  UIReadyState,
  ValidationType,
  LintStatusType,
  LintErrorListType,
  ValidationSectionKey,
  ExecutionSession,
  ResultType,
  FRIENDLY_LANGUAGE_METADATA,
} from "@fastly-fiddle/common";
import * as api from "../lib/api";
import { VCL_FUNCTIONS, ECP_FILES, DEFAULT_SOURCES } from "@fastly-fiddle/common";
import MaintenanceBanner from "./MaintenanceBanner";
import sourceSectionMetadata from "../lib/sourceMetadata";
import React from "react";
import EditorSetPlaceholder from "./EditorSetPlaceholder";

import styles from "./FiddleUI.module.css";

const CodeEditor = React.lazy(() => import("./CodeEditor"));
const CodeEditorSet = React.lazy(() => import("./CodeEditorSet"));
const Result = React.lazy(() => import("./Result"));

export enum Layout {
  APP,
  EMBED,
  EMBED_EDIT,
}

type Props = {
  fiddle: Fiddle;
  lintStatus: LintStatusType;
  readyState?: UIReadyState;
  executionSession?: ExecutionSession;
  isAuthed?: boolean;
  onCloneIntent?: () => void;
  onFreezeIntent?: () => void;
  onUpdateIntent?: (updater: (prevFiddle: Fiddle) => Fiddle) => void;
  onLockIntent?: () => void;
  onExecuteIntent?: (withPurge: boolean) => void;
  onPurgeIntent?: () => void;
  embedID?: number;
  layout: Layout;
  defaultSrc?: string;
  tabs?: string[];
};

type BrowseData = {
  url: string;
  version: number;
};

// Lint status is OK if it hasn't been linted, has no issues, or all issues are warnings
const lintOK = (status: true | undefined | LintErrorListType): boolean => {
  return status === undefined || status === true || (Array.isArray(status) && status.every((err) => err.level === "warning"));
};

const FiddleUI: FunctionComponent<Props> = ({ fiddle, readyState, ...props }: Props) => {
  const [validation, setValidation] = useState<ValidationType>({ request: true, origins: true });
  const [browseData, setBrowseData] = useState<BrowseData>();

  const purgeKey = async (key: string): Promise<void> => {
    if (fiddle.id) await api.purgeKeyInFiddle(fiddle.id, key);
  };

  const updateValidation = (section: ValidationSectionKey, isValid: boolean): void => {
    if (validation[section] !== isValid) setValidation({ ...validation, [section]: isValid });
    if (!isValid) setBrowseData(undefined);
  };

  const handleResize = (newSize: number) => {
    if (!props.embedID) return;
    window.parent.postMessage({ event: "resize", contentHeight: newSize, embedID: props.embedID }, "*");
  };

  const handleResult = (result: ResultType): void => {
    if (props.embedID) {
      window.parent.postMessage({ event: "result", result, embedID: props.embedID }, "*");
    }
    setBrowseData({ url: "https://" + result.execHost, version: result.execVersion });
  };

  const handleExecute = (withPurge: boolean) => {
    setBrowseData(undefined);
    if (props.onExecuteIntent) props.onExecuteIntent(withPurge);
    window.parent.postMessage({ event: "execute", embedID: props.embedID }, "*");
  };

  const handleUpdate = (updater: (prevFiddle: Fiddle) => Fiddle): void => {
    if (props.onUpdateIntent) props.onUpdateIntent(updater);
  };
  const handleClone = (): void => {
    if (props.onCloneIntent) props.onCloneIntent();
  };
  const handleFreeze = (): void => {
    if (props.onFreezeIntent) props.onFreezeIntent();
  };
  const handleLock = (): void => {
    if (props.onLockIntent) props.onLockIntent();
  };
  const handlePurge = (): void => {
    if (props.onPurgeIntent) props.onPurgeIntent();
  };

  const handleBrowse = (evt: React.MouseEvent): void => {
    evt.preventDefault();
    if (browseData && fiddle.type == "vcl" && browseData.version == fiddle.srcVersion) {
      window.open(browseData.url, "_blank");
    } else if (fiddle.type !== "vcl") {
      const baseUrl = new URL(location.origin);
      const urlStr =
        `${baseUrl.protocol}//${fiddle.id}-v${fiddle.srcVersion}.${baseUrl.hostname}` +
        (fiddle.requests.length > 0 ? fiddle.requests[0].path : "/");
      window.open(urlStr, "_blank");
    }
  };

  const allValid =
    Object.values(validation).every(Boolean) &&
    (fiddle.type === "vcl"
      ? VCL_FUNCTIONS.every((srcName) => !fiddle.src[srcName] || lintOK(props.lintStatus[srcName]))
      : ECP_FILES.every((srcName) => lintOK(props.lintStatus[srcName]))) &&
    readyState === "ok" &&
    fiddle.id !== undefined;

  const readOnly = fiddle.isLocked && !props.isAuthed;
  const installable = readOnly && fiddle.title;
  const source = fiddle.src;
  const stateLabel =
    readyState === "loading"
      ? "Loading"
      : readyState === "running"
      ? "Running"
      : readyState === "dirty"
      ? "Editing"
      : readyState === "saving"
      ? "Saving"
      : !fiddle.id
      ? "Unsaved"
      : !(readyState === "ok" && allValid)
      ? "Invalid"
      : undefined;

  if (props.layout === Layout.APP) {
    if (readyState === "loading") {
      return (
        <>
          <Title />
          <div className="loading-screen">
            <img src="/images/progress.svg" className="progress-anim" alt="" />
            <h1>Loading Fiddle</h1>
          </div>
        </>
      );
    }
    return (
      <div>
        <Title
          fiddle={fiddle}
          isAuthed={props.isAuthed || false}
          changeFiddle={handleUpdate}
          onLockIntent={handleLock}
          onCloneIntent={handleClone}
          onFreezeIntent={handleFreeze}
          onPurgeIntent={handlePurge}
          onPurgeKeyIntent={purgeKey}
        />
        <MaintenanceBanner />
        <div className="container">
          <Columns initialOffset={40} minOffset={10}>
            <section>
              {!fiddle.src.manifest ? (
                <Origins
                  changeFiddle={handleUpdate}
                  onValidation={(isValid: boolean): void => updateValidation("origins", isValid)}
                  origins={fiddle.origins}
                  readOnly={readOnly}
                />
              ) : (
                <br />
              )}
              <h2 className={styles.codeHeader}>Your {FRIENDLY_LANGUAGE_METADATA[fiddle.type].shortName || FRIENDLY_LANGUAGE_METADATA[fiddle.type].name} code</h2>
              <Suspense fallback={<EditorSetPlaceholder fiddle={fiddle} />}>
                <CodeEditorSet
                  changeFiddle={handleUpdate}
                  onCloneIntent={handleClone}
                  fiddle={fiddle}
                  readOnly={readOnly}
                  lintStatus={props.lintStatus}
                />
              </Suspense>
            </section>

            <section>
              <div id="requests">
                <h2>Configure requests</h2>
                <div className="button-set">
                  {((!!browseData && browseData.version == fiddle.srcVersion) || fiddle.type !== "vcl") && allValid && (
                    <button onClick={handleBrowse} data-tip="Browse Fiddle in a new tab">
                      <Icon type="pop-out-white" />
                    </button>
                  )}
                  <button
                    className="button-run"
                    onClick={(evt): void => handleExecute(evt.shiftKey)}
                    disabled={!allValid}
                    data-tip="Shift+click to run with empty cache"
                  >
                    {stateLabel || (
                      <>
                        <Icon type="play-white" /> Run
                      </>
                    )}
                  </button>
                </div>
              </div>
              <RequestEditor
                type={fiddle.type}
                isLegacyCompute={!fiddle.src.manifest}
                requests={fiddle.requests}
                readOnly={readOnly}
                onChangeFiddleIntent={handleUpdate}
                onValidationChange={(isValid: boolean): void => updateValidation("request", isValid)}
              />
              {props.executionSession ? (
                <Suspense fallback="">
                  <Result session={props.executionSession} fiddle={fiddle} onResult={handleResult} withInsights withHeader />
                </Suspense>
              ) : (
                <Instructions />
              )}
            </section>
          </Columns>
        </div>
        <ToolTip placement="bottom" type="dark" trigger="hover" target="[data-tip]" contentAttr="data-tip" />
      </div>
    );
  } else {
    const srcNames = Array.from(fiddle.type === "vcl" ? VCL_FUNCTIONS : ECP_FILES);
    const allSourcesAliasIndex = (props.tabs || []).indexOf("srcs");
    if (allSourcesAliasIndex !== -1 && props.tabs) {
      props.tabs.splice(allSourcesAliasIndex, 1, ...srcNames);
    }
    const tabNames = props.tabs || srcNames;
    const populatedTabs = srcNames.filter((s) => tabNames.includes(s) && fiddle.src[s]);
    const specifiedDefaultTab = tabNames.find((f) => f === props.defaultSrc);
    const defaultTab = specifiedDefaultTab || populatedTabs.find((srcKey) => DEFAULT_SOURCES.find((k) => srcKey === k)) || populatedTabs[0];
    const lintErrors = Object.fromEntries(
      srcNames.map((srcName) => {
        const errs = props.lintStatus[srcName];
        return [srcName, errs && Array.isArray(errs) ? errs : []];
      })
    );
    const tabs: TabType[] = srcNames
      .filter((s) => tabNames.includes(s))
      .map((srcName) => ({
        key: srcName,
        label: sourceSectionMetadata[fiddle.type][srcName]?.friendlyLabel || srcName,
        collapsible: true,
        disabled: !fiddle.src[srcName] && (props.layout === Layout.EMBED || readOnly),
        isFile: true,
        content:
          props.layout === Layout.EMBED || readOnly ? (
            <CodeBlock language={fiddle.type} withLineNumbers preHighlightedHtml={fiddle.highlightedText?.[srcName]}>
              {source[srcName] || ""}
            </CodeBlock>
          ) : (
            <Suspense fallback="">
              <CodeEditor
                lintErrors={lintErrors[srcName]}
                language={fiddle.type}
                codeContext={srcName}
                value={fiddle.src[srcName] || ""}
                readOnly={false}
                onChange={(newVal): void => handleUpdate((fiddle) => ({ ...fiddle, src: { ...fiddle.src, [srcName]: newVal } }))}
                visible={true}
                fitMode="container"
              />
            </Suspense>
          ),
      }));

    if (tabs.length > 0) {
      tabs.push({
        key: "spacer",
        spacer: true,
      });
    }

    if ((props.tabs === undefined || props.tabs.includes("install")) && installable) {
      tabs.push({
        key: "install",
        label: "Install",
        content: <Install fiddle={fiddle} />,
      });
    }
    if (props.tabs === undefined || props.tabs.includes("run")) {
      tabs.push({
        key: "run",
        label: "Run",
        tooltip: stateLabel,
        icon: "play",
        disabled: readyState !== "ok" || !allValid,
        onClick: (): void => handleExecute(true),
        content: props.executionSession ? (
          <Suspense fallback="">
            <Result session={props.executionSession} fiddle={fiddle} withHeader={false} withInsights={!readOnly} onResult={handleResult} />
          </Suspense>
        ) : (
          <div className="result">
            <div className="event event-message">Preparing...</div>
          </div>
        ),
      });
    }
    if (props.tabs === undefined || props.tabs.includes("info")) {
      tabs.push({
        key: "reqsummary",
        icon: "info",
        content: <RequestSummary fiddle={fiddle} />,
      });
    }

    const showTabBar = props.tabs === undefined || props.tabs.length > 1;

    if (showTabBar) {
      return (
        <>
          <TabbedContainer
            key={fiddle.id || ""} // Allow default tab to change if a new fiddle is loaded
            default={defaultTab}
            tabs={tabs}
            fitMode={props.layout === Layout.EMBED_EDIT && !readOnly ? "container" : "content"}
            onContentResize={handleResize}
          />
          <ToolTip placement="bottom" type="dark" trigger="hover" target="[data-tip]" contentAttr="data-tip" />
        </>
      );
    }
    const activeTab = tabs.find((tab) => defaultTab === tab.key) || tabs[0];

    return (
      <div className={props.layout === Layout.EMBED_EDIT && !readOnly ? "fullheightPanel" : ""} tabIndex={0}>
        {activeTab.content}
      </div>
    );
  }
};

export default FiddleUI;
