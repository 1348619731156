import { FunctionComponent } from "react";

import styles from "./EditorSetPlaceholder.module.css";
import { ECP_FILES, Fiddle, VCL_FUNCTIONS } from "@fastly-fiddle/common";
import sourceSectionMetadata from "../lib/sourceMetadata";

const EditorSetPlaceholder: FunctionComponent<{fiddle: Fiddle}> = ({ fiddle }) => {
  const fields = fiddle.type === "vcl" ? VCL_FUNCTIONS : ECP_FILES;
  return fields.map((srcName, i) => <div key={i} className={styles.editorPlaceholder}>{sourceSectionMetadata[fiddle.type][srcName]?.friendlyLabel || srcName}</div>);
};

export default EditorSetPlaceholder;
