export class FiddleValidationError extends Error {
}
export class TestValidationError extends Error {
}
export class UpdateConflictError extends Error {
}
export class DebouncedError extends Error {
}
export class SSEServerHangup extends Error {
}
export class APIError extends Error {
    httpStatus;
    constructor(httpStatus, msg) {
        super(msg);
        this.httpStatus = httpStatus;
    }
}
