import { HTTP_METHODS, VCL_FUNCTIONS, EMPTY_FIDDLE, MAX_ORIGIN_LEN, FIDDLE_LANGUAGES, ECP_FILES, REQUEST_DELAY_MIN, REQUEST_DELAY_MAX, } from "./constants.js";
import { FiddleValidationError } from "./errors.js";
export const isJSON = (str) => {
    try {
        JSON.parse(str);
        return true;
    }
    catch (e) {
        return false;
    }
};
export const isObj = (inp) => typeof inp === "object" && inp !== null;
export const isString = (inp) => typeof inp === "string";
export const isScalar = (inp) => ["string", "boolean", "number"].includes(typeof inp);
const isValidReqData = (k, v) => {
    return (k === "dictionaries" &&
        isObj(v) &&
        Object.entries(v).every(([dictName, items]) => {
            return isString(dictName) && isObj(items) && Object.values(items).every(isString);
        }));
};
export const isPartialFiddle = (inp, shouldThrow = true) => isObj(inp) &&
    Object.entries(inp).every(([k, v]) => {
        const isValid = ["schema", "data"].includes(k) || // Ignore old props
            (["id", "title", "description", "dependencyLock", "lastEditor"].includes(k) && isString(v)) ||
            (["srcVersion"].includes(k) && typeof v === "number") ||
            (k === "type" && isString(v) && FIDDLE_LANGUAGES.some((l) => l === v)) ||
            (k === "isLocked" && typeof v === "boolean") ||
            (k === "origins" && Array.isArray(v) && (v.length > 0 || inp["type"] !== "vcl") && v.every((o) => isString(o) && o.length <= MAX_ORIGIN_LEN)) ||
            ((k === "src" || k === "vclTemplated") &&
                isObj(v) &&
                Object.entries(v).filter(([k]) => k !== "manifest" && !(inp["type"] === "go" && k === "deps")).every(([fn, vcl]) => [...VCL_FUNCTIONS, ...ECP_FILES].some((v) => v === fn) && isString(vcl))) ||
            (k === "requests" &&
                Array.isArray(v) &&
                v.length &&
                v.every((r, rIdx) => Object.entries(r).every(([reqK, reqV]) => (["enableWAF"].includes(reqK)) || // Ignore old props
                    (reqK === "method" && HTTP_METHODS.some((m) => m === reqV)) ||
                    (["path", "headers", "body", "sourceIP", "connType", "tests"].includes(reqK) && isString(reqV)) ||
                    (["delay"].includes(reqK) && typeof reqV === "number" && reqV <= REQUEST_DELAY_MAX && reqV >= REQUEST_DELAY_MIN) ||
                    (["enableShield", "enableCluster", "followRedirects", "useFreshCache"].includes(reqK) && typeof reqV === "boolean") ||
                    (["data"].includes(reqK) && isObj(reqV) && Object.entries(reqV).every(([datak, datav]) => isValidReqData(datak, datav))) ||
                    (() => {
                        throw new FiddleValidationError(`Fiddle validation error in request ${rIdx}, key ${reqK}: ${JSON.stringify(reqV)}`);
                    })())));
        if (!isValid && shouldThrow)
            throw new FiddleValidationError(`Fiddle validation error in key '${k}': ${JSON.stringify(v)}`);
        return isValid;
    });
export const asFullFiddle = (inp) => {
    return {
        ...EMPTY_FIDDLE,
        ...inp,
        src: { ...EMPTY_FIDDLE.src, ...inp.src },
        requests: inp.requests
            ? inp.requests.map((r) => ({ ...EMPTY_FIDDLE.requests[0], ...r }))
            : EMPTY_FIDDLE.requests,
    };
};
