import { FIDDLE_ENV } from "@fastly-fiddle/common";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://cd2443554d4d4794ad427ced7b2e1807@o1025883.ingest.us.sentry.io/6156598",
  tunnel: '/sentry/envelope',
  release: import.meta.env.VITE_COMMIT_SHA || undefined,
  environment: FIDDLE_ENV,

  beforeSend(event) {
    // Check if it is an exception, and if so, show the report dialog
    if (event.exception && event.event_id) {
      Sentry.showReportDialog({ eventId: event.event_id, title: 'Fiddle encountered an internal error.' });
    }
    return event;
  }
});
