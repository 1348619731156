import { FunctionComponent, useEffect } from "react";
import { useRoutes, Routes } from "raviger";
import ReactGA from "react-ga";

import { FIDDLE_LANGUAGES, ROUTING_INTENTS } from "@fastly-fiddle/common";
import HomePage from "./HomePage";
import FiddleApp from "./FiddleApp";
import { Layout } from "./FiddleUI";
import { Fiddle, FiddleLanguage } from "@fastly-fiddle/common";
import LanguageSelector from "./LanguageSelector";

type PathParams = {
  id?: string;
  modifier?: string;
  language?: FiddleLanguage;
};

const routes = (ssrData?: Fiddle): Routes<string> => {
  return {
    "/": () => <HomePage />,
    "/new": () => <LanguageSelector computeOnly={false} />,
    "/new/compute": () => <LanguageSelector computeOnly={true} />,
    "/new/:language": ({ language }: PathParams) => {
      if (!language) language = "vcl";
      if (!FIDDLE_LANGUAGES.includes(language)) return <h1>Language not supported</h1>;
      return <FiddleApp newLanguage={language} />;
    },
    "/fiddle/:id": ({ id }: PathParams) => <FiddleApp fiddleID={id} />,
    "/fiddle/:id/:modifier": ({ id, modifier }: PathParams) => (
      <FiddleApp
        fiddleID={id}
        layout={modifier === "embedded" ? Layout.EMBED : Layout.APP}
        intent={ROUTING_INTENTS.find((i) => i === modifier)}
        ssrData={ssrData}
      />
    )
  };
};

type Props = {
  ssrData?: Fiddle;
};

const Router: FunctionComponent<Props> = (props?: Props) => {
  const route = useRoutes(routes(props?.ssrData));

  // On route change, record pageview
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    window.scrollTo(0, 0);
  }, [route]);

  return route ? route : <p>Not found</p>;
};

export default Router;
