import { FunctionComponent } from "react";
import Title from "./Title";
import { Link } from "raviger";
import * as store from "../lib/localStore";
import { EXAMPLE_FIDDLES } from "@fastly-fiddle/common";
import Icon from "./Icon";
import ToolTip from "./Tooltip";

import styles from "./HomePage.module.css";
import FiddleCardGallery from "./FiddleCardGallery";
import { formatDuration } from "../lib/utils";
import FiddleApp from "./FiddleApp";
import { Layout } from "./FiddleUI";

const JUST_NOW_DUR_MS = 1000 * 120;
const RECENT_DUR_MS = 1000 * 3600 * 24 * 90;

const HomePage: FunctionComponent = () => {
  const history = store.getHistory();
  const ownFiddles = [...history]
    .filter(([, v]) => v.created === true)
    .sort((a, b) => ((a[1].lastSaved || 0) < (b[1].lastSaved || 0) ? 1 : -1))
    .map(([k]) => k);
  const recentlyExecuted = [...history]
    .filter(([, v]) => v.lastExecuted !== null)
    .sort((a, b) => ((a[1].lastExecuted || 0) < (b[1].lastExecuted || 0) ? 1 : -1))
    .map(([k]) => k);

  const formatAge = (d?: Date | null, prefix?: string): string => {
    if (!d) return "";
    const now = Date.now();
    const durMS = now - d.getTime();
    if (durMS > RECENT_DUR_MS) return "";
    const ageStr = durMS < JUST_NOW_DUR_MS ? "just now" : formatDuration(durMS * 1000) + " ago";
    return (prefix ? prefix + " " : "") + ageStr;
  };

  return (
    <div>
      <Title />

      <div className="container">
        {ownFiddles.length ? (
          <>
            <h1 className={styles.mainHeading}>
              👋 Welcome back! Your fiddles are saved in your browser so you can pick up where you left off
            </h1>
            <h2>Your fiddles</h2>
            <FiddleCardGallery
              withNew={true}
              ids={ownFiddles}
              metaContent={(id: string) => formatAge(history.get(id)?.lastSaved, "edited")}
            />
          </>
        ) : (
          <>
            <h1 className={styles.mainHeading}>Try Fastly in your browser — no setup needed 🚀</h1>
            <p>
              Check out one of these example fiddles or{" "}
              <strong>
                <Link href="/new">create your own</Link>
              </strong>
              . For more code examples, check out{" "}
              <a href="https://developer.fastly.com/solutions/examples">the solutions gallery on developer.fastly.com</a>.
            </p>
            <FiddleCardGallery ids={EXAMPLE_FIDDLES} />
          </>
        )}

        {Boolean(recentlyExecuted.length) && (
          <section className={styles.section}>
            <h2>Recently executed</h2>
            <FiddleCardGallery ids={recentlyExecuted} metaContent={(id: string) => formatAge(history.get(id)?.lastExecuted)} />
          </section>
        )}

        <section className={styles.section}>
          <div className={styles.media}>
            <div>
              <h2>Experiment, test, share, embed</h2>
              <p>
                In a fiddle you can do almost anything you can do in a real Fastly service, but there’s no need to sign up or log in. You
                can share your fiddles with friends and colleagues, even embed them on any website you like.
              </p>
              <p>
                Most code examples on <a href="https://developer.fastly.com">developer.fastly.com</a> are fiddle embeds!
              </p>
            </div>
            <div className={styles.example}>
              <FiddleApp fiddleID="30cdd0f3" layout={Layout.EMBED} setDocumentTitle={false} />
            </div>
          </div>
        </section>

        {ownFiddles.length ? (
          <section className={styles.section}>
            <h2>More examples</h2>
            <FiddleCardGallery ids={EXAMPLE_FIDDLES} />
            <p>
              For more code examples, check out{" "}
              <a href="https://developer.fastly.com/solutions/examples">the solutions gallery on developer.fastly.com</a>.
            </p>
          </section>
        ) : (
          <section className={styles.section}>
            <h2>Get started</h2>
            <div className={styles.media}>
              <div>
                <p>
                  There’s plenty to read and learn, or get hands-on and make your first fiddle with a single click. No setup or account
                  necessary!
                </p>
                <ul>
                  <li>
                    <a href="https://developer.fastly.com/learning/tools/fiddle">Fiddle Documentation</a>
                  </li>
                  <li>
                    <a href="https://developer.fastly.com/learning">Fastly Documentation</a>
                  </li>
                </ul>
              </div>
              <div className={styles.new}>
                <Link href="/new">
                  <h3 className={styles.bigNew}>
                    <Icon type="rocket" /> Start a new fiddle
                  </h3>
                </Link>
              </div>
            </div>
          </section>
        )}
      </div>
      <ToolTip placement="bottom" type="dark" trigger="hover" target="[data-tip]" contentAttr="data-tip" />
    </div>
  );
};

export default HomePage;
