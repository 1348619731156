export const VCL_FUNCTIONS = ["init", "recv", "hash", "hit", "miss", "pass", "fetch", "error", "deliver", "log"];
export const HTTP_METHODS = ["GET", "HEAD", "POST", "PUT", "PATCH", "DELETE", "OPTIONS", "PURGE", "INVALID"];
export const ECP_FILES = ["manifest", "deps", "main"];
export const FIDDLE_LANGUAGES = ["vcl", "rust", "javascript", "go"];
export const DEFAULT_SOURCES = ["main", "recv"];
export const DEBOUNCE_INPUT = 1000;
export const RESULT_STREAM_TIMEOUT = 60000;
export const COMPUTE_COMPILE_TIMEOUT = 90000;
export const NUM_VCL_ORIGINS = 5;
export const MAX_ORIGIN_LEN = 255;
export const VCL_ORIGIN_PATTERN = /^(https?:\/\/)([a-zA-Z0-9.-]+)(?:(:\d+))?\/?$/i;
export const REQURL_PATTERN = /^\/\S*$/i;
export const HTTP_HEADER_PATTERN = /^([\w-_]+):\s*(.+?)$/i;
export const MAX_BODY_BUFFER = 1024 * 1024 * 10;
export const INSTALLABLE_ECP_DATE = new Date(2021, 10, 12, 0, 0, 0);
export const FRIENDLY_LANGUAGE_METADATA = {
    vcl: {
        name: 'Varnish Configuration Language',
        shortName: 'VCL',
        description: 'Domain-specific language for configuring Fastly CDN services.'
    },
    rust: {
        name: 'Rust',
        description: 'Systems programming language that runs blazingly fast and prevents common memory-related issues. Supports all Compute platform features.'
    },
    javascript: {
        name: 'JavaScript',
        description: 'High-level, interpreted programming language that runs on Compute using the Spidermonkey engine. Supports most Compute platform features.'
    },
    go: {
        name: 'Go',
        description: 'Compiled, statically typed language with a focus on simplicity and efficiency. Supports most Compute platform features.'
    }
};
export const LONGSTR_LEN = 30;
export const REPORTABLE_METAFIELDS = [
    "restarts",
    "method",
    "url",
    "lastuse",
    "isESI",
    "isPush",
    "isPCI",
    "isH2",
    "gzip",
    "return",
    "staleExists",
    "age",
    "hits",
    "ttl",
    "swr",
    "sie",
    "state",
    "status",
    "cacheable",
    "backend",
    "ignoreBusy",
    "alwaysMiss",
    "digest",
    "skeys",
];
export const BOOL_METAFIELDS = ["isESI", "isPCI", "gzip", "cacheable", "staleExists", "respComplete"];
export const DUR_METAFIELDS = ["age", "ttl", "swr", "sie", "lastuse", "respBodyBytesReceived"];
export const ECP_EVENTS = [
    "ecp-start",
    "ecp-end",
    "ecp-cache-hit",
    "geo",
    "dict-open",
    "dict-get",
    "object-store-open",
    "object-store-lookup",
    "object-store-insert",
    "dynamic-backend-registration",
    "secret-store-open",
    "secret-store-lookup"
];
export const ROUTING_INTENTS = ["clone", "learn"];
export const ECP_COMPILER_URL_DEFAULT = "http://build.compute.fiddle.gcp.secretcdn.net:8003";
const fiddleBranch = import.meta?.env ? import.meta.env.VITE_FIDDLE_BRANCH : process.env.VITE_FIDDLE_BRANCH;
export const FIDDLE_ENV = fiddleBranch ? (fiddleBranch === "main" ? "prod" : `branch-${fiddleBranch}`) : "dev";
export const IS_DEVELOPMENT = !import.meta.env?.PROD && process.env.NODE_ENV !== "production";
export const REQUEST_DELAY_MIN = 0; // seconds
export const REQUEST_DELAY_MAX = 10; // seconds
export const MAX_GALLERY_SIZE = 12;
export const STATUS_TEXTS = {
    "100": "Continue",
    "101": "Switching Protocols",
    "103": "Early Hints",
    "200": "OK",
    "201": "Created",
    "202": "Accepted",
    "203": "Non-Authoritative Information",
    "204": "No Content",
    "205": "Reset Content",
    "206": "Partial Content",
    "300": "Multiple Choices",
    "301": "Moved Permanently",
    "302": "Found",
    "303": "See Other",
    "304": "Not Modified",
    "307": "Temporary Redirect",
    "308": "Permanent Redirect",
    "400": "Bad Request",
    "401": "Unauthorized",
    "402": "Payment Required",
    "403": "Forbidden",
    "404": "Not Found",
    "405": "Method Not Allowed",
    "406": "Not Acceptable",
    "407": "Proxy Authentication Required",
    "408": "Request Timeout",
    "409": "Conflict",
    "410": "Gone",
    "411": "Length Required",
    "412": "Precondition Failed",
    "413": "Payload Too Large",
    "414": "URI Too Long",
    "415": "Unsupported Media Type",
    "416": "Range Not Satisfiable",
    "417": "Expectation Failed",
    "418": "I'm a teapot",
    "422": "Unprocessable Entity",
    "425": "Too Early",
    "426": "Upgrade Required",
    "428": "Precondition Required",
    "429": "Too Many Requests",
    "431": "Request Header Fields Too Large",
    "451": "Unavailable For Legal Reasons",
    "500": "Internal Server Error",
    "501": "Not Implemented",
    "502": "Bad Gateway",
    "503": "Service Unavailable",
    "504": "Gateway Timeout",
    "505": "HTTP Version Not Supported",
    "506": "Variant Also Negotiates",
    "507": "Insufficient Storage",
    "508": "Loop Detected",
    "510": "Not Extended",
    "511": "Network Authentication Required",
};
export const EMPTY_FIDDLE = {
    id: undefined,
    type: "vcl",
    title: "",
    description: "",
    origins: ["https://http-me.glitch.me"],
    src: {},
    requests: [
        {
            method: "GET",
            path: "/status=200",
            headers: "",
            body: "",
            data: {},
            enableCluster: true,
            enableShield: false,
            useFreshCache: false,
            connType: "h2",
            sourceIP: "client",
            followRedirects: false,
            tests: "",
            delay: 0,
        },
    ],
    isLocked: false,
};
export const EXAMPLE_FIDDLES = [
    "701d48ce",
    "d4a375bf",
    "0f8a3640",
    "78c50399",
    "296463fa",
    "d96e18b2",
    "1817d24b",
    "5a081bd3",
    "7910aa85",
    "6ac9d8f3",
    "3a6c16f5",
];
