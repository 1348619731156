import OTel, { SpanStatusCode } from "@opentelemetry/api";
export async function traceSpan(tName, sName, arg3, arg4, arg5) {
    const attrs = arguments.length >= 4 ? arg3 : {};
    const parentContext = arguments.length == 5 ? arg4 : OTel.context.active();
    const callback = (arguments.length == 5 ? arg5 : arguments.length == 4 ? arg4 : arg3);
    const tracer = OTel.trace.getTracer(tName);
    const handlesSpanEnd = callback.length;
    return tracer.startActiveSpan(sName, { attributes: attrs }, parentContext, async (span) => {
        const boundCallback = handlesSpanEnd ? callback.bind(null, span, OTel.context.active()) : callback;
        try {
            const result = await boundCallback();
            if (!handlesSpanEnd)
                span.end();
            return result;
        }
        catch (e) {
            span.recordException(e);
            span.setStatus({ code: SpanStatusCode.ERROR });
            span.end();
            throw e;
        }
    });
}
export const spanEvent = (name, attributes) => {
    OTel.trace.getSpan(OTel.context.active())?.addEvent(name, attributes);
};
