import { FunctionComponent, useState, useRef, useEffect } from "react";
import classnames from "classnames";
import ToolTip from "./Tooltip";
import Icon from "./Icon";

import styles from "./TabbedContainer.module.css";
import { useQueryParams } from "raviger";

export type TabType = {
  content?: JSX.Element;
  disabled?: boolean;
  key: string;
  icon?: string;
  href?: string;
  label?: string;
  tooltip?: string;
  onClick?: () => void;
  spacer?: boolean;
  collapsible?: boolean;
  isFile?: boolean;
};

type Props = {
  default: string;
  tabs: TabType[];
  fitMode: "content" | "container";
  onContentResize?: (newSize: number) => void;
};

const TabbedContainer: FunctionComponent<Props> = (props: Props) => {
  const [activeTabKey, setActiveTabKey] = useState<string>(props.default);
  const [menuVisible, setMenuVisible] = useState<boolean>(false);
  const [mutationObserver, setMutationObserver] = useState<MutationObserver>();

  const anchorEl = useRef<HTMLLIElement>(null);
  const menuEl = useRef<HTMLUListElement>(null);
  const contentEl = useRef<HTMLDivElement>(null);

  const [queryParams] = useQueryParams();

  const handleMenuClick = () => {
    setTimeout(() => setMenuVisible(true), 10);
  };

  const selectTab = (tab: TabType): void => {
    if (tab.spacer) return;
    if (tab.href) {
      window.open(tab.href, "_blank");
    } else {
      setActiveTabKey(tab.key);
    }
    tab.onClick && tab.onClick();
    setMenuVisible(false);
  };

  const sendResize = () => {
    props.onContentResize &&
      contentEl.current &&
      menuEl.current &&
      props.onContentResize(contentEl.current.scrollHeight + menuEl.current.scrollHeight);
  };

  useEffect(() => {
    if (contentEl.current) {
      const observer = new MutationObserver(sendResize);
      observer.observe(contentEl.current, { childList: true, subtree: true });
      setMutationObserver(observer);
    }
    sendResize();
    return () => mutationObserver && mutationObserver.disconnect();
  }, [contentEl]);

  const activeTab = props.tabs.find((tab) => activeTabKey === tab.key) || props.tabs[0];
  const collapsible = props.tabs.filter((t) => t.collapsible && !t.spacer && !t.disabled);

  const containerStyles = [styles.container, styles["fitmode_" + props.fitMode]];
  if (queryParams.border === "0") containerStyles.push(styles.noBorder);

  return (
    <div className={classnames(containerStyles)}>
      <ul ref={menuEl}>
        {!!collapsible.length && (
          <>
            <li className={`${styles.file} ${styles.collapsed}`} ref={anchorEl} onClick={handleMenuClick}>
              {activeTab.label || "Menu"} <Icon type="caret" />
            </li>
            {anchorEl.current && (
              <ToolTip
                visible={menuVisible}
                onHideIntent={(): void => setMenuVisible(false)}
                placement="bottom"
                trigger="manual"
                target={anchorEl.current}
              >
                <ul className="pick-list">
                  {collapsible.map((tab) => (
                    <li key={tab.key} className={`${styles.file} ${activeTab.key === tab.key ? styles.active : ""}`}>
                      <label>
                        <input type="radio" value={tab.key} checked={activeTab.key === tab.key} onChange={(): void => selectTab(tab)} />
                        {(tab.label || tab.key)}
                      </label>
                    </li>
                  ))}
                </ul>
              </ToolTip>
            )}
          </>
        )}
        {(props.tabs || []).map((tab) => (
          <li
            key={tab.key}
            className={classnames({
              [styles.active]: activeTab === tab,
              [styles.space]: tab.spacer,
              [styles.disabled]: tab.disabled,
              [styles.collapsible]: tab.collapsible,
              [styles.file]: tab.isFile,
            })}
            onClick={(): void => {
              if (!tab.disabled) selectTab(tab);
            }}
            aria-disabled={tab.disabled || undefined}
            data-tip={tab.tooltip}
          >
            {tab.icon && <Icon type={tab.icon} />}
            {tab.label}
          </li>
        ))}
      </ul>
      <div className={styles.panel} tabIndex={0} ref={contentEl}>
        {activeTab.content}
      </div>
    </div>
  );
};

export default TabbedContainer;
