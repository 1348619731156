export default {
  "request-line": {
    pattern: /^(?:POST|GET|PUT|DELETE|OPTIONS|PATCH|TRACE|CONNECT|PURGE)\s(?:https?:\/\/|\/)\S+\sHTTP\/[0-9.]+/m,
    inside: {
      // HTTP Verb
      verb: /^(?:POST|GET|PUT|DELETE|OPTIONS|PATCH|TRACE|CONNECT|PURGE)\b/,
      // Path or query argument
      "attr-name": /:\w+/,
    },
  },
  "response-status-h1": {
    alias: "response-status",
    pattern: /^HTTP\/1.[01] \d+.*/m,
    inside: {
      "status-text": {
        pattern: /(^HTTP\/1.[01] \d+ )\w.*$/i,
        lookbehind: true,
      },
      "status-code": {
        pattern: /(^HTTP\/1.[01] )\d+/i,
        lookbehind: true,
      },
    },
  },
  "response-status-h2": {
    alias: "response-status",
    pattern: /^HTTP\/2 \d+.*/m,
    inside: {
      "status-text": {
        pattern: /(^HTTP\/2 \d+ )\w.*$/i,
        lookbehind: true,
      },
      "status-code": {
        pattern: /(^HTTP\/2 )\d+/i,
        lookbehind: true,
      },
    },
  },
  "header-line": {
    pattern: /^[\w-]+: .*$/m,
    inside: {
      "header-name": /^[\w-]+:(?=.)/m,
    },
  },
};
