import { FunctionComponent, useState, useRef } from "react";
import classnames from "classnames";
import { Fiddle, FiddleSources } from "@fastly-fiddle/common";
import ToolTip from "./Tooltip";
import Install from "./Install";
import Modal from "./Modal";
import CopyToClipboardButton from "./CopyToClipboardButton";
import * as passwordStore from "../lib/localStore";

import styles from "./AuthStatus.module.css";

type Props = {
  onCloneIntent: () => void;
  onLockIntent: () => void;
  onFreezeIntent: () => void;
  onPurgeIntent: () => void;
  onPurgeKeyIntent: (key: string) => void;
  fiddle: Fiddle;
  isAuthed: boolean;
};
type MenuOption = {
  key: string;
  label: string;
  tip?: string;
  disabled?: boolean;
  fn: () => void;
};

const states = {
  frozen: {
    label: "Read-only",
    actionsAvailable: ["clone", "embed", "install"],
    tip: "You can't edit this fiddle, but you can run it.  To edit, click the clone button to create a new, editable version.",
  },
  open: {
    label: "Open",
    actionsAvailable: ["clone", "lock", "freeze", "embed", "purge", "purgekey"],
    tip: "Anyone that knows the URL can see and edit this fiddle. If multiple users are editing at the same time, edits may conflict. Click Lock to restrict editing to just yourself.",
  },
  locked: {
    label: "Locked",
    actionsAvailable: ["clone", "freeze", "embed", "install", "purge", "purgekey"],
    tip: "Anyone can see and run this fiddle, but only you can edit it.  Feel free to share the link: other people will be able to clone it to create their own version but they can't affect yours.  Click freeze to make it read-only.",
  },
};

const NON_EMBEDDABLE = ["id", "isLocked"];
const EMBED_CLIENT_CODE = `<script defer src="${location.origin}/embed.js"></script>`;

const AuthStatus: FunctionComponent<Props> = (props: Props) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [modal, setModal] = useState<string | null>(null);
  const [embedString, setEmbedString] = useState<string | undefined>();
  const menuRefEl = useRef<HTMLElement>(null);

  const toggleMenu = (newState?: boolean): void => {
    requestAnimationFrame(() => {
      setMenuVisible(newState === undefined ? !menuVisible : newState);
    });
  };

  const purgeKeyPrompt = (): void => {
    const key = window.prompt("Enter key to purge");
    if (key) {
      props.onPurgeKeyIntent(key);
    }
  };

  const showEmbed = (): void => {
    const abbrevFiddleObj = Object.fromEntries(
      Object.entries(props.fiddle).map(([k, v]) => {
        if (k === "origins") {
          v = props.fiddle.origins.filter(Boolean);
        } else if (k === "src") {
          v = Object.fromEntries(Object.entries(v as FiddleSources).filter(([, v]) => v));
        } else if (k === "requests") {
          v = props.fiddle.requests.map((req) => {
            const { headers, body, data, enableCluster, enableShield, connType, sourceIP, ...mandatory } = req;
            return Object.assign(
              {},
              { enableCluster: !!enableCluster, enableShield: !!enableShield },
              headers ? { headers } : undefined,
              body ? { body } : undefined,
              Object.keys(data).length ? { data } : undefined,
              connType !== "h2" ? { connType } : undefined,
              sourceIP !== "client" ? { sourceIP } : undefined,
              mandatory
            );
          });
        } else if (NON_EMBEDDABLE.includes(k) || v === "") {
          v = undefined;
        }
        return [k, v];
      })
    );
    const code = '<script type="application/json+fiddle">\n' + JSON.stringify(abbrevFiddleObj, null, "  ") + "\n</script>";
    setEmbedString(code);
    setModal("embed");
  };

  if (!props.fiddle.id) {
    return null;
  }

  const menuOptions: MenuOption[] = [
    {
      key: "clone",
      label: "Clone",
      tip: "Make a copy that you can edit",
      fn: (): void => {
        window.open("/fiddle/" + props.fiddle.id + "/clone", "_blank");
      },
    },
    {
      key: "lock",
      label: "Lock",
      tip: "Restrict editing of the fiddle to this device",
      fn: props.onLockIntent,
      disabled: !passwordStore.isAvailable(),
    },
    { key: "freeze", label: "Freeze", tip: "Prevent further edits", fn: props.onFreezeIntent, disabled: !passwordStore.isAvailable() },
    { key: "embed", label: "Embed", tip: "Show the fiddle in another website", fn: showEmbed },
    { key: "install", label: "Install", tip: "Add the fiddle code to your Fastly service", fn: (): void => setModal("install") },
    { key: "purge", label: "Purge full cache", tip: "Clear the cache used by the fiddle", fn: props.onPurgeIntent },
    {
      key: "purgekey",
      label: "Purge a key",
      tip: "Clear items from the cache which are tagged with a specific surrogate key",
      fn: purgeKeyPrompt,
    },
  ];
  const chooseMenuAction = (actionKey: string): void => {
    setMenuVisible(false);
    const menuItem = menuOptions.find((item) => item.key === actionKey);
    if (menuItem) menuItem.fn(); // TODO: Could use optional prop operator here?
  };

  const stateKey = props.fiddle.isLocked ? (props.isAuthed ? "locked" : "frozen") : "open";
  const thisState = states[stateKey];
  const supportsInstall = Boolean(props.fiddle.title);

  return (
    <span ref={menuRefEl} className={classnames(styles["auth-status"], styles[stateKey])}>
      <label data-tip={thisState.tip}>{thisState.label}</label>
      <span onClick={(): void => toggleMenu()} className={styles["menu-toggler"]} />
      {menuRefEl.current && (
        <ToolTip
          visible={menuVisible}
          onHideIntent={(): void => toggleMenu(false)}
          placement="bottom"
          trigger="manual"
          target={menuRefEl.current}
        >
          <ul className="pick-list">
            {menuOptions
              .filter((item) => thisState.actionsAvailable.includes(item.key) && (item.key !== "install" || supportsInstall))
              .map((item, idx) => (
                <li key={idx} className={item.disabled ? styles.disabled : ""}>
                  <label
                    data-menutip={item.tip}
                    onClick={(): void => {
                      !item.disabled && chooseMenuAction(item.key);
                    }}
                  >
                    {item.label}
                  </label>
                </li>
              ))}
          </ul>
        </ToolTip>
      )}

      <Modal visible={modal === "embed"} onHideIntent={(): void => setModal(null)}>
        <h2>Embed this fiddle in another website</h2>
        <p>
          First, put this code just before your <code>&lt;/body&gt;</code>:
          <CopyToClipboardButton text={EMBED_CLIENT_CODE} />
        </p>
        <pre>
          <code>{EMBED_CLIENT_CODE}</code>
        </pre>
        <p>
          Now, place the embed code where you want the fiddle to appear:
          <CopyToClipboardButton text={embedString} />
        </p>
        <pre className={styles.embedCode}>
          <code>{embedString}</code>
        </pre>
        <p>
          The first non-empty subroutine is displayed by default. Add a <code>data-default-src="..."</code> attribute to select a specific
          one.
        </p>
      </Modal>

      <Modal big visible={modal === "install"} onHideIntent={(): void => setModal(null)}>
        <Install fiddle={props.fiddle} />
      </Modal>

      <ToolTip placement="left" type="dark" trigger="hover" target="[data-menutip]" contentAttr="data-menutip" />
    </span>
  );
};

export default AuthStatus;
