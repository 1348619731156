import { FunctionComponent, useEffect, useState, useCallback, useRef, PropsWithChildren } from "react";
import ReactGA from "react-ga";
import { useQueryParams, navigate } from "raviger";
import { Span, Context } from "@opentelemetry/api";

import { traceSpan } from "@fastly-fiddle/common";
import { asFullFiddle, isPartialFiddle } from "@fastly-fiddle/common";
import { DebouncedError, FiddleValidationError } from "@fastly-fiddle/common";
import { abortableWait } from "../lib/utils";
import * as api from "../lib/api";
import * as store from "../lib/localStore";

import { EMPTY_FIDDLE, DEBOUNCE_INPUT, ROUTING_INTENTS } from "@fastly-fiddle/common";
import {
  Fiddle,
  UIReadyState,
  ExecutionSession,
  LintStatusType,
  AbortablePromise,
  FiddleLanguage,
  FiddleSources,
} from "@fastly-fiddle/common";

import FiddleUI, { Layout } from "./FiddleUI";

import "../lib/style/main.scss";
import { APIError } from "@fastly-fiddle/common";

type RoutingIntent = (typeof ROUTING_INTENTS)[number];

const checkAuthed = (fiddleID: string): boolean => store.isAvailable() && !!store.getPassword(fiddleID);
const fiddleUrl = (id: string, layout?: Layout): string => `/fiddle/${id}${layout === Layout.EMBED ? "/embedded" : ""}`;

const fetchSourceBoilerplate = async (language: FiddleLanguage): Promise<FiddleSources> => {
  if (language === "vcl") return {};

  const sdkInfos = await api.getEcpSdkInfos();

  return sdkInfos?.[language]?.src_boilerplate ?? {};
};

type AppProps = {
  fiddleID?: string;
  newLanguage?: FiddleLanguage;
  layout?: Layout;
  intent?: RoutingIntent;
  ssrData?: Fiddle;
  setDocumentTitle?: boolean;
};

const FiddleApp: FunctionComponent<AppProps> = (props?: PropsWithChildren<AppProps>) => {
  const layout = props?.layout || Layout.APP;

  const [queryParams] = useQueryParams();

  const [fiddle, setFiddle] = useState<Fiddle>(
    asFullFiddle(
      props?.ssrData
        ? props.ssrData
        : props?.newLanguage
          ? { type: props.newLanguage, src: props.newLanguage !== "vcl" ? { manifest: " " } : {} }
          : {}
    )
  );
  const [cacheID, setCacheID] = useState<number>(() => Math.floor(Math.random() * 1000));
  const [lintStatus, setLintStatus] = useState<LintStatusType>({});
  const [readyState, setReadyState] = useState<UIReadyState>(props?.fiddleID && fiddle.id !== props.fiddleID ? "loading" : "ok");
  const [executionSession, setExecutionSession] = useState<ExecutionSession>();
  const saveOperation = useRef<AbortablePromise<unknown> | null>(null);

  const saveFiddle = useCallback(
    async (newFiddleData: Fiddle): Promise<void> => {
      try {
        if (saveOperation.current) saveOperation.current.abort(); // Cancel the existing save
        saveOperation.current = abortableWait(DEBOUNCE_INPUT);
        await saveOperation.current; // May be aborted by new input
        await traceSpan("App", "Save fiddle", async () => {
          setReadyState("saving");
          const isNew = !newFiddleData.id || newFiddleData.id.length > 10;
          const apiRequest = isNew ? api.createFiddle(newFiddleData) : api.updateFiddle(newFiddleData);
          saveOperation.current = apiRequest;
          const response = await apiRequest; // May be aborted by new input
          if (!response.fiddle.id) throw new Error("Save operation failed to create ID");
          setFiddle((prev) => ({ ...prev, id: response.fiddle.id, srcVersion: response.fiddle.srcVersion }));
          setLintStatus(response.lintStatus || {});
          if (response.fiddle.id && (!props || response.fiddle.id !== props.fiddleID)) {
            const url = new URL(window.location.href);
            url.pathname = fiddleUrl(response.fiddle.id, layout);
            navigate(url.toString(), { replace: true });
          }
          setReadyState("ok");
          saveOperation.current = null;
          if (isNew) {
            store.recordCreation(response.fiddle.id);
          } else {
            store.recordSave(response.fiddle.id);
          }
        });
      } catch (e) {
        const newInputReceived = e instanceof DebouncedError;
        const fiddleInvalid = e instanceof FiddleValidationError;
        const serverSideAbort = e instanceof APIError && e.httpStatus == 409;
        if (!newInputReceived && !fiddleInvalid && !serverSideAbort) {
          throw e;
        }
      }
    },
    [props]
  );

  const updateFiddle = useCallback(
    (updater: (prevFiddle: Fiddle) => Fiddle): void => {
      setReadyState("dirty");
      setFiddle((prev) => {
        const updated = updater(prev);

        if (isPartialFiddle(updated.id ? updated : { ...updated, id: "id" }, false)) {
          saveFiddle(updated);
        }
        return updated;
      });
    },
    [saveFiddle]
  );

  const loadFiddle = useCallback(
    async (id: string, mode: "load" | "clone" | "learn"): Promise<void> => {
      traceSpan("App", "Load fiddle", async () => {
        try {
          setReadyState("loading");
          const response = await (mode === "clone" || mode === "learn" ? api.cloneFiddle(id) : api.getFiddle(id));
          if (response.fiddle.id) {
            const newFiddle = response.fiddle;

            // Compat: if Compute and no deps, it needs upgrading before it can be edited
            if (newFiddle.type !== "vcl" && !newFiddle.src.deps && (!newFiddle.isLocked || checkAuthed(response.fiddle.id))) {
              const ecpSdkInfos = await api.getEcpSdkInfos();
              newFiddle.src.deps = ecpSdkInfos?.[newFiddle.type]?.src_boilerplate?.deps;
            }

            if (mode === "learn") {
              updateFiddle(() => ({ ...newFiddle, src: { ...EMPTY_FIDDLE.src } }));
            } else {
              setFiddle(newFiddle);
            }
            setLintStatus(response.lintStatus || {});
            if (!props || response.fiddle.id !== props.fiddleID) {
              navigate(fiddleUrl(response.fiddle.id, layout), { replace: true });
            }
            setReadyState("ok");
            store.recordAccess(response.fiddle.id);
          }
        } catch (e) {
          if (e instanceof APIError && e.httpStatus === 404) {
            navigate("/");
          } else {
            throw e;
          }
        }
      });
    },
    [props, saveFiddle]
  );

  const lockFiddle = useCallback(async (): Promise<void> => {
    if (!fiddle.id) return;
    await api.lockFiddle(fiddle.id);
    setFiddle((prev) => ({ ...prev, isLocked: true }));
  }, [fiddle.id]);

  const freezeFiddle = useCallback(async (): Promise<void> => {
    if (!fiddle.id) return;
    if (!fiddle.isLocked) await lockFiddle();
    store.removePassword(fiddle.id);
    setFiddle((prev) => ({ ...prev, isLocked: true }));
  }, [fiddle.id, fiddle.isLocked, lockFiddle]);

  const executeFiddle = useCallback(
    async (withPurge: boolean): Promise<void> => {
      traceSpan("App", "Execute fiddle", async (span, context) => {
        if (!fiddle.id) return;
        ReactGA.event({ category: "execute", action: "User executed the fiddle", label: fiddle.id });
        setReadyState("running");
        const thisCacheID = withPurge ? cacheID + 1 : cacheID;
        if (thisCacheID !== cacheID) setCacheID(thisCacheID);
        const resp = await api.execute(fiddle.id, thisCacheID);
        if (resp.lintStatus) {
          setLintStatus(resp.lintStatus || {});
        } else {
          setExecutionSession({
            id: resp.sessionID,
            streamHost: resp.streamHost,
            telemetry: { span: span as Span, context: context as Context },
          });
          setReadyState("ok");
          store.recordExecution(fiddle.id);
        }
      });
    },
    [fiddle.id, cacheID]
  );

  // On route change, trigger load if needed
  useEffect(() => {
    if (props && (props.fiddleID !== fiddle.id || !props.fiddleID)) {
      if (props.fiddleID) {
        loadFiddle(props.fiddleID, props.intent || "load");
      } else {
        const urlParams = new URLSearchParams(window.location.search);
        const preconfiguredTitle = urlParams.get("title") ? decodeURIComponent(urlParams.get("title") || "") : null;

        (async () => {
          const fiddleType = props.newLanguage || "vcl";
          const srcBoilerplate = await fetchSourceBoilerplate(fiddleType);
          setFiddle({
            ...EMPTY_FIDDLE,
            type: fiddleType,
            src: srcBoilerplate,
            title: preconfiguredTitle || "",
          });
          setLintStatus({});
          setReadyState("ok");
        })();
      }
    }
  }, [props, fiddle.id]);

  useEffect(() => {
    if (!props || props.setDocumentTitle !== false) {
      document.title = (fiddle.title ? fiddle.title + " - " : "") + "Fastly Fiddle";
    }
  }, [fiddle.title]);

  const isAuthed = fiddle.id ? checkAuthed(fiddle.id) : false;

  return (
    <FiddleUI
      fiddle={fiddle}
      readyState={readyState}
      lintStatus={lintStatus}
      executionSession={executionSession}
      isAuthed={isAuthed}
      onCloneIntent={(): void => {
        fiddle.id && loadFiddle(fiddle.id, "clone");
      }}
      onFreezeIntent={freezeFiddle}
      onUpdateIntent={updateFiddle}
      onLockIntent={lockFiddle}
      onExecuteIntent={executeFiddle}
      onPurgeIntent={(): void => setCacheID(cacheID + 1)}
      embedID={queryParams.embedID}
      layout={layout === Layout.EMBED && queryParams.edit ? Layout.EMBED_EDIT : layout}
      tabs={"tabs" in queryParams ? queryParams.tabs.split(",") : undefined}
      defaultSrc={queryParams.defaultSrc || queryParams.defaultSub}
    />
  );
};

export default FiddleApp;
