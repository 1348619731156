/**
 * A naive YAMLesque parser and serialiser to avoid adding a weighty
 * YAML implementation to the front end bundle (at time of writing,
 * js-yaml was the only library that seemed to work well and added
 * almost 100k)
 */

import { RequestDataType } from "@fastly-fiddle/common";
import { isObj } from "@fastly-fiddle/common";

type ItemType = string | { [key: string]: ItemType };

const TOP_LEVEL_KEYS = ["dictionaries"] as const;

export function parse(str: string): RequestDataType {
  const out: Partial<RequestDataType> = {};
  const path: Array<ItemType> = [out];
  const indents = [-1];
  str.split("\n").forEach((line) => {
    if (!line.trim()) return;
    const [, indentStr, key, , rawValue] = line.match(/^(\s*)([^\s:"']+):(?:\s+(['"]?)(.*)(\3))?\s*$/) || [null, null, null, null];
    if (!key) throw new Error("Invalid line in data definition: " + line);
    const indent = indentStr ? indentStr.length : 0;
    const value: ItemType | string = rawValue ? String(rawValue) : {};
    const lastIndent = indents[indents.length - 1];
    if (indent > lastIndent) {
      const pathEntry = path[path.length - 1];
      if (isObj(pathEntry)) {
        if (pathEntry === out && !TOP_LEVEL_KEYS.some((k) => k === key)) throw new Error("Invalid key at top level");
        pathEntry[key] = value;
        path.push(pathEntry[key]);
        indents.push(indent);
      } else {
        throw new Error(`Can't attach ${key} to a non object`);
      }
    } else if (indent === lastIndent) {
      const pathEntry = path[path.length - 2];
      if (isObj(pathEntry)) {
        if (pathEntry === out && !TOP_LEVEL_KEYS.some((k) => k === key)) throw new Error("Invalid key at top level");
        pathEntry[key] = value; // Add as sibling
        path[path.length - 1] = pathEntry[key];
      } else {
        throw new Error(`Can't attach ${key} to a non object`);
      }
    } else {
      const pathIdx = indents.lastIndexOf(indent);
      if (pathIdx > 0) {
        const pathEntry = path[pathIdx - 1];
        if (pathEntry === out && !TOP_LEVEL_KEYS.some((k) => k === key)) throw new Error("Invalid key at top level");
        if (isObj(pathEntry)) {
          path.splice(pathIdx, path.length);
          indents.splice(pathIdx, path.length);
          pathEntry[key] = value;
          path.push(pathEntry[key]);
        } else {
          throw new Error(`Can't attach ${key} to a non object`);
        }
      } else {
        throw new Error(`Cannot find a parent for '${key}'`);
      }
    }
  });
  return out;
}

export function stringify(inp: RequestDataType, indent = 0): string {
  return Object.entries(inp)
    .map(([k, v]) => " ".repeat(indent) + k + ":" + (isObj(v) ? "\n" + stringify(v, indent + 2) : ` "${v}"`))
    .join("\n");
}
