import { FunctionComponent, useState, useEffect, useRef } from "react";
import styles from "./Columns.module.css";

const LS_KEY = "fiddle-col-width";
let lsAvailable = false;
let savedWidth: number;

try {
  savedWidth = Number.parseFloat(window.localStorage.getItem("fiddle-col-width") || "0");
  lsAvailable = true;
} catch (e) {
  console.warn("Unable to use localStorage");
}

type Props = {
  minOffset?: number;
  initialOffset?: number;
  onResize?: () => void;
  children: React.ReactNode;
};

const Columns: FunctionComponent<Props> = ({ children, minOffset, initialOffset, onResize }: Props) => {
  const elWrapper = useRef<HTMLDivElement>(null);
  const elHandle = useRef<HTMLDivElement>(null);
  const offsetRef = useRef<number>();
  const [offset, setOffset] = useState<number>(savedWidth || initialOffset || 0);

  offsetRef.current = offset;

  useEffect(() => {
    const handleMouseDown = (e: MouseEvent) => {
      e.preventDefault();
      document.addEventListener("mousemove", handleMove);
      document.addEventListener("mouseup", handleRelease);
    };

    const handleMove = (e: MouseEvent) => {
      if (!elHandle.current || !elWrapper.current) return;
      const offset = ((e.clientX - elWrapper.current.offsetLeft - elHandle.current.offsetWidth / 2) / elWrapper.current.offsetWidth) * 100;
      setOffset(Math.max(minOffset || 0, offset));
      onResize && onResize();
    };

    const handleRelease = () => {
      document.removeEventListener("mousemove", handleMove);
      document.removeEventListener("mouseup", handleRelease);
      if (lsAvailable) window.localStorage.setItem(LS_KEY, String(offsetRef.current));
    };

    elHandle.current?.addEventListener("mousedown", handleMouseDown);
    return () => elHandle.current?.removeEventListener("mousedown", handleMouseDown);
  }, [minOffset, onResize]);

  if (Array.isArray(children) && children.length === 2) {
    return (
      <div className={styles.wrapper} ref={elWrapper}>
        <div className={styles.left} style={{ width: offset + "%" }}>
          {children[0]}
        </div>
        <div className={styles.handle} ref={elHandle}></div>
        <div className={styles.right}>{children[1]}</div>
      </div>
    );
  } else {
    return <>{children}</>;
  }
};

export default Columns;
