import { FiddleLanguage } from "@fastly-fiddle/common";

type SourceSectionMetadata = Record<
  string,
  {
    friendlyLabel?: string;
    docsLink?: string;
  }
>;

const defaultSourceSectionMetadata: SourceSectionMetadata = {
  manifest: { friendlyLabel: "fastly.toml", docsLink: "https://www.fastly.com/documentation/reference/compute/fastly-toml/" },
  deps: { friendlyLabel: "Dependencies" },
  main: { friendlyLabel: "Edge logic" }
};

const sourceSectionMetadata: Record<FiddleLanguage, SourceSectionMetadata> = {
  vcl: {},
  rust: {
    ...defaultSourceSectionMetadata,
    main: { friendlyLabel: "src/main.rs", docsLink: "https://docs.rs/fastly" },
    deps: { friendlyLabel: "Cargo.toml" },
  },
  javascript: {
    ...defaultSourceSectionMetadata,
    main: { friendlyLabel: "src/index.js", docsLink: "https://js-compute-reference-docs.edgecompute.app/" },
    deps: { friendlyLabel: "package.json" },
  },
  go: {
    ...defaultSourceSectionMetadata,
    main: { friendlyLabel: "main.go", docsLink: "https://pkg.go.dev/github.com/fastly/compute-sdk-go" },
    deps: { friendlyLabel: "go.mod" },
  },
};

export default sourceSectionMetadata;
