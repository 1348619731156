import { FunctionComponent, useEffect, useState } from "react";
import { Link } from "raviger";
import { getFiddles } from "../lib/api";
import { Fiddle } from "@fastly-fiddle/common";
import * as store from "../lib/localStore";
import Icon from "./Icon";

import styles from "./FiddleCardGallery.module.css";
import { MAX_GALLERY_SIZE } from "@fastly-fiddle/common";

type Props = {
  ids: string[];
  withNew?: boolean;
  metaContent?: (arg0: string) => string;
};
const FiddleCardGallery: FunctionComponent<Props> = (props: Props) => {
  const [fiddles, setFiddles] = useState<Record<string, Fiddle | undefined>>(() =>
    props.ids.reduce((acc, id) => ({ ...acc, [id]: null }), {})
  );

  useEffect(() => {
    const fiddlesToFetch = props.ids.slice(0, MAX_GALLERY_SIZE);
    (async function () {
      const fiddles = await getFiddles(fiddlesToFetch);
      setFiddles(fiddles);
    })();
  }, [props.ids]);

  let gallerySize = MAX_GALLERY_SIZE;
  if (props.withNew) gallerySize--;

  return (
    <>
      <div className={styles.fiddleCards}>
        {Boolean(props.withNew) && (
          <div className={styles.new}>
            <Link href="/new">
              <h3>+ Create a new fiddle</h3>
            </Link>
          </div>
        )}
        {fiddles &&
          Object.entries(fiddles)
            .sort(([a], [b]) => props.ids.indexOf(a as string) - props.ids.indexOf(b as string))
            .slice(0, gallerySize)
            .map(([id, fiddle], idx) =>
              fiddle ? (
                <div key={`${fiddle.id}+${idx}`} className={styles[fiddle.type]}>
                  <Link href={`/fiddle/${fiddle.id}`}>
                    <div className={styles.meta}>
                      <div className={styles.language} title={fiddle.type}>
                        <Icon type={`language-${fiddle.type}`} />
                      </div>
                      <div className={styles.iconMeta}>
                        {fiddle.isLocked && store.getPassword(String(fiddle.id)) ? (
                          <span data-tip="Edits restricted to this device">
                            <Icon type="account-lock" />
                          </span>
                        ) : fiddle.isLocked ? (
                          <span data-tip="Read-only">
                            <Icon type="lock" />
                          </span>
                        ) : (
                          <span data-tip="Editable by anyone">
                            <Icon type="earth" />
                          </span>
                        )}
                      </div>
                    </div>
                    <div className={styles.content}>
                      <h3>{fiddle.title || fiddle.id}</h3>
                      {props.metaContent && <div className={styles.metaContent}>{props.metaContent(id)}</div>}
                    </div>
                  </Link>
                </div>
              ) : (
                <div key={`${id}+${idx}`} className={styles.loading}>
                  <a></a>
                </div>
              )
            )}
        {/** TODO: [27 more...] revealer */}
      </div>
    </>
  );
};

export default FiddleCardGallery;
