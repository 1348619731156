import ReactDOM from "react-dom/client";
import "./lib/frontend-telemetry";

import "./lib/instrument";
import * as Sentry from "@sentry/react";

import Router from "./components/Router";

import { IS_DEVELOPMENT, SSRData } from "@fastly-fiddle/common";
import { StrictMode } from "react";
declare global {
  interface Window {
    FastlyFiddleState?: SSRData;
  }
}

if (window.location.search.includes("fiddle-early-access") && window.localStorage) {
  window.localStorage.setItem("fiddle-manifests-early-access", "true");
  const u = new URL(location.href);
  u.searchParams.delete("fiddle-early-access");
  window.history.replaceState(null, "", u.toString());
  window.location.reload();
}

const root = document.getElementById("root");
if (root != null) {
  // wipe out the content of the HTML before attempting render
  root.innerHTML = "";

  const router = (
    <Sentry.ErrorBoundary>
      <Router ssrData={window.FastlyFiddleState?.fiddle} />
    </Sentry.ErrorBoundary>
  );

  const reactRoot = ReactDOM.createRoot(root);
  reactRoot.render(IS_DEVELOPMENT ? <StrictMode>{router}</StrictMode> : router);
}
